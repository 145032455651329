@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
    height: 100%;
}
#root {
    display: flex;
    flex-direction: column;
}
.content {
    flex: 1 0 auto;
}
.footer {
    flex-shrink: 0;
}

.loading {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #fff2;
    z-index: 999;
}
.spinner {
    left: 50%;
    margin-left: -4em;
    font-size: 10px;
    animation: spin infinite 5s linear;
    color: gray;
}
.spinner,
.spinner:after {
    border-radius: 50%;
    width: 8em;
    height: 8em;
    display: block;
    position: absolute;
    top: 50%;
    margin-top: -4.05em;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@media print {
    body {
        font-size: 14px;
    }
    .no-print,
    .no-print * {
        display: none !important;
    }

    #root * {
        overflow: hidden;
        print-color-adjust: exact;
        box-shadow: none;
    }

    .no-print-break-inside {
        break-inside: avoid;
    }
    .md\:ml-64 {
        margin-left: 0 !important;
    }
}

.after-colon {
    position: relative;
}
.after-colon::after {
    content: ':';
    position: absolute;
    right: 0.5rem;
}

.dashboard-list table tbody tr:last-child {
    background: rgb(243 244 246);
    font-weight: bold;
}

.chevron-down {
    margin-left: auto;
    transition: transform 0.25s cubic-bezier(0, 0, 0, 1);
}
.szh-accordion {
    border-bottom: 1px solid #ccc;
}
.szh-accordion__item {
    border-top: 1px solid #ccc;
}
.szh-accordion__item-btn {
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0;
    padding: 1rem;
    font-size: 1rem;
    font-weight: 400;
    text-align: left;
    color: #007bff;
    background-color: transparent;
    border: none;
}
.szh-accordion__item-btn:hover {
    background-color: #f3f3f3;
}
.szh-accordion__item-content {
    transition: height 0.25s cubic-bezier(0, 0, 0, 1);
}
.szh-accordion__item-panel {
    padding: 1rem;
}
.szh-accordion__item--expanded .szh-accordion__item-btn {
    background-color: #e7e7e7;
}
.szh-accordion__item--expanded .chevron-down {
    transform: rotate(180deg);
}

.react-datepicker-wrapper {
    width: 100%;
}

.upload__image-wrapper {
    display: flex;
    align-items: center;
    padding: 1rem;
}

.upload__image-wrapper .image-item {
    position: relative;
    border: 1px solid;
    margin: 0.5rem;
    width: 7.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.upload__image-wrapper .image-item .img-wrapper {
    height: 10rem;
}

.upload__image-wrapper .image-item .img-wrapper .img {
    margin: 0.5rem;
    height: calc(100% - 24px);
    display: flex;
    justify-content: center;
    align-items: center;
}
.upload__image-wrapper .image-item .img-label {
    width: 100%;
    text-align: center;
    background: rgb(59 51 130);
    color: white;
}
.upload__image-wrapper .image-item .image-item__btn-wrapper {
    position: absolute;
    top: -10px;
    right: -10px;
    background: black;
    border-radius: 50%;
    color: white;
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    justify-content: center;
}
